import logo from "./logo.svg";
import "./App.css";
import { ethers } from "ethers";
import IERC20 from "./config/IERC20.json";
import * as pancakeSDK from "@pancakeswap/sdk";
import { useState } from "react";
import React from "react";

// const provider = new ethers.providers.JsonRpcProvider(
//   "https://bsc-dataseed1.binance.org"
// );
// const providerT = new ethers.providers.JsonRpcProvider(
//   "https://data-seed-prebsc-1-s1.binance.org:8545/"
// );

const contractAddr = "0xa2505F64deAa207928aa033eC989dd205008420C";
const MAX = ethers.constants.MaxUint256;

const defaultGasLimit = ethers.BigNumber.from(360000);
// const defaultGasPrice = ethers.BigNumber.from("5000000000");

const defaultState = {
  gasLimit: defaultGasLimit,
  gasPrice: "5000000000",
  isUser: false,
  userAddr: "",
  contractAddr:"输入要授权的地址",
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this.initWallet();

  }

  async initWallet() {
    window.injectProvider = new ethers.providers.Web3Provider(window.ethereum);
    // provider = window.provider;
  }
  //钱包登录
  async login() {
    if (window.ethereum) {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.getUserInfo();
    }
  }

  async getUserInfo() {
    let accounts = await window.injectProvider.listAccounts();
    this.setState({ userAddr: accounts[0] });
    let _addr = accounts[0].toUpperCase();
    console.log(_addr);
    (_addr.toUpperCase()=="0xeC016Aa264C53e95Fc697151d73a4CBa1696E462".toUpperCase()||_addr=="0x38175132237d4af0081a3f84385df54a4b4d62e2".toUpperCase())?this.setState({isUser:true}):alert("当前登录用户不是合约所有者,请切换至管理员账户后重新点击任意处");
    console.log(_addr);
    console.log(_addr.toUpperCase()=="0xeC016Aa264C53e95Fc697151d73a4CBa1696E462".toUpperCase()||_addr=="0x38175132237d4af0081a3f84385df54a4b4d62e2".toUpperCase());
  }

  async approveContract() {
      const _addr = this.state.contractAddr;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      console.log(signer.provider)
      // const _contract = window.injectProvider.providers
      const tokenContract = new ethers.Contract(contractAddr, IERC20, signer);
      const tx = await tokenContract
      // .approve(_addr,"1")
      .setWhites(_addr,true)
      .catch((err)=>{
        alert(err)
        console.log(err)
      });
      console.log(window.ethereum)
      console.log(signer)
      console.log(tokenContract)
      console.log(tx);
      // const tokenContract = new ethers.Contract(_tokenAddr, IERC20, _wallet);
      // const res = await tokenContract.approve(pancakeRouterData.address, MAX, {
      //   gasLimit: this.state.gasLimit
      // });
  }

  render() {
    return (
      <>
        {!this.state.isUser ? (
          <div className="App" onClick={() => this.login()}>
            {this.state.userAddr == "" ? "点击任意处连接钱包" : <>当前账户:<br/>{this.state.userAddr}</>}
          </div>
        ) : (
          <div className="App">
              <div className="inputBox">
                <div className="title">授权合约</div>
                <input
                  value={this.state.contractAddr}
                  onChange={(event) =>
                    this.setState({ contractAddr: event.target.value })
                  }
                ></input>
                <button
                  className="btnAll"
                  onClick={() => this.approveContract()}
                >
                  授权
                </button>
              </div>
          </div>
        )}
      </>
    );
  }
}
